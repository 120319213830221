import React, { useState } from 'react';
import './Contato.css';
import { FaPhone, FaEnvelope, FaMapMarkerAlt } from 'react-icons/fa';

function Contato() {
  const [formData, setFormData] = useState({
    nome: '',
    email: '',
    telefone: '',
    mensagem: ''
  });

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    // Add form submission logic here
    console.log('Form submitted:', formData);
  };

  return (
    <div className="contato-page">
      <div className="contato-header">
        <h1>Entre em <span className="highlight">Contato</span></h1>
        <p>Estamos prontos para ajudar sua empresa a se transformar digitalmente</p>
      </div>

      <div className="contato-content">
        <div className="info-section">
          <div className="info-item">
            <FaPhone className="info-icon" />
            <div>
              <h3>Telefone</h3>
              <p>(37) 99844-5678</p>
            </div>
          </div>
          <div className="info-item">
            <FaEnvelope className="info-icon" />
            <div>
              <h3>Email</h3>
              <p>contato@dadosinteligentes.com</p>
            </div>
          </div>
          <div className="info-item">
            <FaMapMarkerAlt className="info-icon" />
            <div>
              <h3>Localização</h3>
              <p>Sul de Minas Gerais, Brasil</p>
            </div>
          </div>
        </div>

        <form className="contato-form" onSubmit={handleSubmit}>
          <div className="form-group">
            <input
              type="text"
              name="nome"
              placeholder="Seu Nome"
              value={formData.nome}
              onChange={handleChange}
              required
            />
          </div>
          <div className="form-group">
            <input
              type="email"
              name="email"
              placeholder="Seu Email"
              value={formData.email}
              onChange={handleChange}
              required
            />
          </div>
          <div className="form-group">
            <input
              type="tel"
              name="telefone"
              placeholder="Seu Telefone"
              value={formData.telefone}
              onChange={handleChange}
              required
            />
          </div>
          <div className="form-group">
            <textarea
              name="mensagem"
              placeholder="Sua Mensagem"
              value={formData.mensagem}
              onChange={handleChange}
              required
            ></textarea>
          </div>
          <button type="submit" className="submit-button">Enviar Mensagem</button>
        </form>
      </div>
    </div>
  );
}

export default Contato;