import React from 'react';
import { Link } from 'react-router-dom';
import './FifthSection.css';
import case1 from './case1.webp';
import case2 from './case2.webp';
import case3 from './case3.webp';
import case4 from './case4.webp';

function FifthSection() {
  return (
    <section className="fifth-section">
      <div className="fifth-section-overlay">
        <div className="fifth-section-content">
          <div className="fifth-section-text">
            <h2 className="fifth-section-title">Portfolio</h2>
            <h3 className="fifth-section-subtitle">
              Alguns <span className="highlight">Cases</span> de Sucesso
            </h3>
            <ul className="fifth-section-list">
              <li>Histórico comprovado de sucesso em diversos projetos</li>
              <li>Expertise em uma ampla gama de indústrias</li>
              <li>Soluções inovadoras</li>
              <li>Impulsionamento de clientes para alcançar resultados excepcionais</li>
            </ul>
            <div className="button-container">
              <Link to="/cases" className="fifth-section-button">Leia Mais</Link>
            </div>
          </div>
          <div className="fifth-section-cases">
            <div className="case-grid">
              <img src={case1} alt="Case 1" className="case-image" />
              <img src={case2} alt="Case 2" className="case-image" />
              <img src={case3} alt="Case 3" className="case-image" />
              <img src={case4} alt="Case 4" className="case-image" />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default FifthSection;