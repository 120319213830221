import React from 'react';
import './Solucoes.css';
import solucao1Img from './solucao1.png';
import solucao2Img from './solucao2.png';
import { FaDatabase, FaCogs, FaRobot, FaChartLine, FaChartBar, FaLaptopCode } from 'react-icons/fa';

function Solucoes() {
  const solucoes = [
    { icon: <FaDatabase />, title: 'Engenharia de Dados', desc: 'Transformamos dados brutos em insights valiosos:', bullets: ['ETL e data pipelines', 'Data lakes e warehouses', 'Processamento em tempo real', 'Governança de dados'] },
    { icon: <FaCogs />, title: 'Automação de Processos', desc: 'Otimizamos operações para maior eficiência:', bullets: ['RPA (Robotic Process Automation)', 'Workflows inteligentes', 'Integração de sistemas', 'Monitoramento e otimização contínua'] },
    { icon: <FaRobot />, title: 'Inteligência Artificial', desc: 'Implementamos IA avançada para impulsionar inovação:', bullets: ['Machine Learning', 'Deep Learning', 'Processamento de linguagem natural', 'Visão computacional'] },
    { icon: <FaChartLine />, title: 'Algoritmos Preditivos', desc: 'Prevemos tendências para decisões estratégicas:', bullets: ['Modelos estatísticos avançados', 'Técnicas de data mining', 'Análise de séries temporais', 'Otimização de portfólio'] },
    { icon: <FaChartBar />, title: 'Sistemas de BI', desc: 'Fornecemos insights acionáveis em tempo real:', bullets: ['Dashboards interativos', 'Relatórios personalizados', 'KPIs em tempo real', 'Análise de dados ad-hoc'] },
    { icon: <FaLaptopCode />, title: 'Desenvolvimento de Aplicações', desc: 'Criamos soluções sob medida para seu negócio:', bullets: ['Aplicativos móveis', 'Sistemas empresariais', 'Plataformas web escaláveis', 'Integrações API'] }
  ];

  return (
    <div className="solucoes-page">
      <header className="solucoes-header">
        <h1><span className="highlight">Soluções</span> Personalizadas</h1>
      </header>

      <section className="solucoes-personalizadas">
        <div className="solucoes-grid">
          {solucoes.map((solucao, index) => (
            <div key={index} className="solucao-card">
              {solucao.icon}
              <h3>{solucao.title}</h3>
              <p>{solucao.desc}</p>
              <ul>
                {solucao.bullets.map((bullet, idx) => (
                  <li key={idx}>{bullet}</li>
                ))}
              </ul>
            </div>
          ))}
        </div>
      </section>
      <section className="solucoes-cta">
        <h2>Transforme seu Negócio com Soluções Personalizadas</h2>
        <p>Soluções sob medida para resolver seus desafios específicos. <br /> 
        Precisa de ajuda para saber o que seu negócio precisa? <br />
        </p>
        <button className="cta-button">Agende uma Consultoria Gratuita</button>
      </section>
    </div>
  );
}

export default Solucoes;
