// SolutionsPage.js
import React from 'react';
import './Solucoes_old.css';

const SolutionsPage = () => {
  return (
    <div className="solutions-page">
      <div className="solutions-container">
        {/* Hero Section */}
        <div className="solucoes1-hero-section">
          <h1>Soluções</h1>
          <p>Transformamos desafios em oportunidades, criando soluções sob medida que geram valor real para o negócio.</p>
        </div>

        {/* Solutions Grid */}
        <div className="solutions-grid">
          <div className="solution-card">
            <div className="solution-content">
              <h2>Tech-as-a-Service</h2>
              <h3>Consultoria e Desenvolvimento de Soluções Personalizadas</h3>
              <p>Oferecemos serviços tecnológicos abrangentes, desde estruturação de dados até o desenvolvimento de complexas soluções com IA. Nossa abordagem personalizada permite que empresas aproveitem seus dados ao máximo, otimizando processos e impulsionando inovação.</p>
              <h3>Transformamos desafios em oportunidades, criando soluções sob medida que geram valor real para o negócio.</h3>
              <h4>Extraia o verdadeiro poder dos seus dados</h4>
              <button className="solution-button">Saiba mais!</button>
            </div>
          </div>

          <div className="solution-card">
            <div className="solution-content">
              <h2>Chatbots Inteligentes</h2>
              <h3>Atendimento e Interação Automatizada com Inteligência Artificial</h3>
              <p>Automatizamos agendamentos com Chatbots Inteligentes, eliminando intervenção humana em tarefas repetitivas. Nosso sistema gerencia múltiplos agendamentos, integra-se a calendários existentes e utiliza processamento de linguagem natural para oferecer opções personalizadas.</p>
              <h3>Resultado: otimização de recursos, redução de erros e tempos de espera, maior eficiência e satisfação do cliente.</h3>
              <h4>Automatize agendamentos e eleve a satisfação do cliente</h4>
              <button className="solution-button">Conheça já nossa plataforma!</button>
            </div>
          </div>

          <div className="solution-card">
            <div className="solution-content">
              <h2>DocAI</h2>
              <h3>Leitura e Processamento de Documentos com Inteligência Artificial</h3>
              <p>Simplificamos operações diárias através da automação de tarefas repetitivas, garantindo precisão e eficiência. Por meio de nossa solução DocAI, automatizamos processos de entrada de dados e leitura de documentos, economizando tempo e minimizando erros operacionais.</p>
              <h4>Otimize seus processos com nossa solução de automação</h4>
              <button className="solution-button">Agende uma demonstração!</button>
            </div>
          </div>

          <div className="solution-card">
            <div className="solution-content">
              <h2>Visão Computacional</h2>
              <h3>Análise de Imagens e Vídeos com Inteligência Artificial</h3>
              <p>Nossas soluções transformam imagens e vídeos em dados acionáveis. Um exemplo prático é a implementação de sistemas de reconhecimento facial para segurança ou análise de comportamento do cliente em lojas físicas.</p>
              <h4>Eleve o nível de suas operações com visão computacional</h4>
              <button className="solution-button">Veja como podemos ajudar!</button>
            </div>
          </div>

          <div className="solution-card">
            <div className="solution-content">
              <h2>Agentes Autônomos</h2>
              <h3>Automação Completa de Processos Empresariais</h3>
              <p>Utilizando avançados algoritmos de IA, nossos agentes aprendem, adaptam-se e executam processos de forma independente, maximizando eficiência e precisão.</p>
              <h3>Desde análise de dados até tomada de decisões, nossos agentes autônomos operam 24/7, reduzindo erros humanos e liberando sua equipe para focar em atividades estratégicas.</h3>
              <h4>Transforme sua operação com inteligência autônoma</h4>
              <button className="solution-button">Fale com nossos especialistas!</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SolutionsPage;
