import React from 'react';
import { useNavigate } from 'react-router-dom';
import './Plataformas.css';
import { FaCalendarCheck, FaChartLine, FaRobot, FaFileAlt } from 'react-icons/fa';

function Plataformas() {
  const navigate = useNavigate();

  const platforms = [
    {
      icon: <FaChartLine />,
      title: 'Torre de Controle',
      description: 'Plataforma de monitoramento em tempo real para gestão eficiente de operações.',
      benefits: [
        'Monitoramento em tempo real',
        'Alertas preditivos',
        'Otimização de recursos',
        'Análise de performance',
        'Integração com sistemas existentes',
        'Relatórios automatizados'
      ],
      path: '/plataformas/torre-controle'
    },
    {
      icon: <FaCalendarCheck />,
      title: 'Minner AI Agendamentos',
      description: 'Sistema inteligente de agendamentos que otimiza recursos e reduz no-shows.',
      benefits: [
        'Redução de até 40% em não comparecimentos',
        'Otimização automática de agenda',
        'Integração com múltiplos canais',
        'Lembretes inteligentes personalizados',
        'Dashboard de análise em tempo real',
        'Previsão de demanda com IA'
      ],
      path: '/plataformas/ai-agendamentos'
    },
    {
      icon: <FaRobot />,
      title: 'AI Agents',
      description: 'Agente virtual inteligente para atendimento, suporte e automação.',
      benefits: [
        'Atendimento 24/7',
        'Integração com WhatsApp',
        'Aprendizado contínuo',
        'Respostas personalizadas',
        'Análise de sentimento',
        'Escalabilidade automática'
      ],
      path: '/plataformas/ai-agents'
    },
    {
      icon: <FaFileAlt />,
      title: 'DocAI',
      description: 'Processamento inteligente de documentos com IA.',
      benefits: [
        'Extração automática de dados',
        'Classificação de documentos',
        'Validação inteligente',
        'Integração com sistemas',
        'Redução de erros manuais',
        'Processamento em lote'
      ],
      path: '/plataformas/doc-ai'
    }
  ];

  const handleSaibaMais = (path) => {
    if (path) {
      navigate(path);
    }
  };

  return (
    <div className="plataformas-page">
      <section className="plataformas-hero">
        <h1>Nossas <span className="highlight">Plataformas</span></h1>
        <p>Soluções prontas e validadas para transformar seu negócio</p>
      </section>

      <section className="plataformas-grid">
        {platforms.map((platform, index) => (
          <div key={index} className="platform-card">
            <div className="platform-icon">{platform.icon}</div>
            <h2>{platform.title}</h2>
            <p className="platform-description">{platform.description}</p>
            <ul className="platform-benefits">
              {platform.benefits.map((benefit, idx) => (
                <li key={idx}>{benefit}</li>
              ))}
            </ul>
            <button 
              className="platform-button"
              onClick={() => handleSaibaMais(platform.path)}
            >
              Saiba Mais
            </button>
          </div>
        ))}
      </section>
      <section className="plataformas-cta">
        <h2>Transforme sua Operação com Inteligência Artificial</h2>
        <p>Nossas plataformas combinam tecnologia de ponta e usabilidade para entregar resultados reais. <br /> Seja otimizando recursos ou automatizando processos, nós impulsionamos a eficiência e inovação.</p>
        <button className="cta-button">Agende uma Demonstração Gratuita</button>
      </section>
    </div>
  );
}

export default Plataformas;