// Footer.js
import React from 'react';
import './Footer.css';

const Footer = () => {
  return (
    <footer className="footer">
      <div className="footer-container">
        {/* Dados Inteligentes Section */}
        <div className="footer-section">
          <h3 className="footer-title">Dados Inteligentes</h3>
          <div className="footer-underline"></div>
          <p className="footer-text">
            Especialistas em Análise de Dados e Inteligência Artificial, nossa missão é transformar dados em insights
            valiosos e criar soluções inovadoras que impulsionem o sucesso dos nossos clientes.
          </p>
        </div>

        {/* Links Rápidos Section */}
        <div className="footer-section">
          <h3 className="footer-title">Links Rápidos</h3>
          <div className="footer-underline"></div>
          <ul className="footer-links">
            <li><a href="/">Home</a></li>
            <li><a href="/servicos">Serviços</a></li>
            <li><a href="/artigos">Artigos</a></li>
            <li><a href="/sobre">Sobre</a></li>
            <li><a href="/privacidade">Política de Privacidade</a></li>
          </ul>
        </div>

        {/* Entre em Contato Section */}
        <div className="footer-section">
          <h3 className="footer-title">Entre em Contato</h3>
          <div className="footer-underline"></div>
          <div className="contact-info">
            <p>
              <span className="contact-icon">📷</span>
              dadosinteligentes_
            </p>
            <p>
              <span className="contact-icon">📞</span>
              (37) 99844-5678
            </p>
            <p>
              <span className="contact-icon">✉️</span>
              contato@dadosinteligentes.com
            </p>
          </div>
        </div>

        {/* Newsletter Section */}
        <div className="footer-section">
          <h3 className="footer-title">Newsletter</h3>
          <div className="footer-underline"></div>
          <p className="footer-text">
            Assine nossa newsletter para receber as últimas atualizações e novidades
            diretamente no seu e-mail.
          </p>
        </div>
      </div>
    </footer>
  );
};

export default Footer;