// Home/index.js
import React from 'react';
import FirstSection from './FirstSection/FirstSection';
import ThirdSection from './ThirdSection/ThirdSection';
import FourthSection from './FourthSection/FourthSection';
import FifthSection from './FifthSection/FifthSection';
import SecondSection from './SecondSection/SecondSection';
//import SixthSection from './SixthSection/SixthSection';

function Home() {
  return (
    <div className="home">
      <FirstSection />
      <ThirdSection />
      <SecondSection />
      <FourthSection />
      <FifthSection /> 
    </div>
  );
}

export default Home;

//<SixthSection />