import React, { useState } from 'react';
import './AIAgents.css';
import { FaRobot, FaComments, FaChartLine, FaBrain, FaArrowRight, FaArrowLeft, FaRocket, FaCogs, FaUsers, FaCheckCircle, FaFileDownload } from 'react-icons/fa';
import DocumentRequestForm from './components/DocumentRequestForm';

function AIAgents() {
  const [currentSlide, setCurrentSlide] = useState(0);
  const [isFormOpen, setIsFormOpen] = useState(false);

  const slides = [
    {
      id: 'intro',
      title: 'AI Agents Platform',
      subtitle: 'Revolucione seu atendimento com nossa solução de IA conversacional',
      content: (
        <div className="hero-content">
          <FaRobot className="hero-icon" />
          <div className="hero-stats">
            <div className="stat-item">
              <h3>98%</h3>
              <p>Satisfação do Cliente</p>
            </div>
            <div className="stat-item">
              <h3>24/7</h3>
              <p>Disponibilidade</p>
            </div>
            <div className="stat-item">
              <h3>-40%</h3>
              <p>Custos Operacionais</p>
            </div>
          </div>
        </div>
      )
    },
    {
      id: 'how-it-works',
      title: 'Como Funciona',
      content: (
        <div className="process-steps">
          <div className="step">
            <FaCogs className="step-icon" />
            <h4>1. Integração</h4>
            <p>Implementação simples com suas ferramentas existentes</p>
          </div>
          <div className="step">
            <FaBrain className="step-icon" />
            <h4>2. Treinamento</h4>
            <p>IA aprende seu negócio e políticas</p>
          </div>
          <div className="step">
            <FaUsers className="step-icon" />
            <h4>3. Atendimento</h4>
            <p>Interação natural com seus clientes</p>
          </div>
          <div className="step">
            <FaChartLine className="step-icon" />
            <h4>4. Análise</h4>
            <p>Métricas e insights em tempo real</p>
          </div>
        </div>
      )
    },
    {
      id: 'features',
      title: 'Recursos Avançados',
      content: (
        <div className="features-grid">
          <div className="feature-card">
            <FaComments className="feature-icon" />
            <h3>Multi-idiomas</h3>
            <p>Suporte em mais de 50 idiomas</p>
          </div>
          <div className="feature-card">
            <FaBrain className="feature-icon" />
            <h3>Aprendizado Contínuo</h3>
            <p>Melhoria constante nas respostas</p>
          </div>
          <div className="feature-card">
            <FaCheckCircle className="feature-icon" />
            <h3>Personalização Total</h3>
            <p>Adaptável ao seu negócio</p>
          </div>
        </div>
      )
    },
    {
      id: 'getting-started',
      title: 'Como Começar',
      content: (
        <div className="getting-started">
          <div className="mindmap">
            <div className="mindmap-center">
              <FaRocket className="mindmap-center-icon" />
              <h3>Comece Aqui</h3>
            </div>
            <div className="mindmap-items">
              <div className="mindmap-item item-1">
                <span className="mindmap-number">1</span>
                <div className="mindmap-content">
                  <h4>Agende uma Demo</h4>
                  <p>Conheça todas as funcionalidades</p>
                </div>
              </div>
              <div className="mindmap-item item-2">
                <span className="mindmap-number">2</span>
                <div className="mindmap-content">
                  <h4>Customização</h4>
                  <p>Adaptamos a IA ao seu negócio</p>
                </div>
              </div>
              <div className="mindmap-item item-3">
                <span className="mindmap-number">3</span>
                <div className="mindmap-content">
                  <h4>Treinamento</h4>
                  <p>Capacitação da sua equipe</p>
                </div>
              </div>
              <div className="mindmap-item item-4">
                <span className="mindmap-number">4</span>
                <div className="mindmap-content">
                  <h4>Lançamento</h4>
                  <p>Sua IA pronta para uso</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      )
    },
    {
      id: 'pricing',
      title: 'Planos e Preços',
      subtitle: 'Escolha o plano ideal para seu negócio',
      content: (
        <div className="pricing-cards">
          <div className="price-card">
            <h3>Professional</h3>
            <div className="price">R$ 2997<span>/mês</span></div>
            <ul>
              <li>Até 1000 interações/mês</li>
              <li>5 integrações</li>
              <li>Suporte 8/5</li>
              <li>Analytics básico</li>
              <li>Treinamento básico</li>
            </ul>
            <button className="price-button">Começar Agora</button>
          </div>
          <div className="price-card business">
            <h3>Enterprise</h3>
            <div className="price">Personalizado</div>
            <ul>
              <li>Interações ilimitadas</li>
              <li>Integrações ilimitadas</li>
              <li>Suporte 24/7</li>
              <li>Analytics avançado</li>
              <li>IA personalizada</li>
              <li>Consultoria dedicada</li>
            </ul>
            <button className="price-button">Falar com Consultor</button>
          </div>
        </div>
      )
    }
  ];

  const nextSlide = () => {
    setCurrentSlide((prev) => (prev + 1) % slides.length);
  };

  const prevSlide = () => {
    setCurrentSlide((prev) => (prev - 1 + slides.length) % slides.length);
  };

  return (
    <div className="ai-assistant-page">
      <div className="page-header">
        <h1 className="main-title">AI Agents</h1>
        <div className="title-underline"></div>
      </div>
      
      <div className="presentation-container">
        <div className="slide-controls">
          <button onClick={prevSlide} className="nav-button">
            <FaArrowLeft />
          </button>
          <div className="slide-indicators">
            {slides.map((_, index) => (
              <div
                key={index}
                className={`indicator ${index === currentSlide ? 'active' : ''}`}
                onClick={() => setCurrentSlide(index)}
              />
            ))}
          </div>
          <button onClick={nextSlide} className="nav-button">
            <FaArrowRight />
          </button>
        </div>

        <div className="slide-content">
          <h1>{slides[currentSlide].title}</h1>
          {slides[currentSlide].subtitle && (
            <p className="slide-subtitle">{slides[currentSlide].subtitle}</p>
          )}
          {slides[currentSlide].content}
        </div>
      </div>

      <button 
        className="floating-cta"
        onClick={() => setIsFormOpen(true)}
      >
        <FaFileDownload className="cta-icon" />
        <span>Documentação Completa</span>
      </button>

      <DocumentRequestForm 
        isOpen={isFormOpen}
        onClose={() => setIsFormOpen(false)}
      />
    </div>
  );
}

export default AIAgents; 