// Home/FirstSection.js
import React, { useEffect, useRef } from 'react';
import './FirstSection.css';

function FirstSection() {
  const sectionRef = useRef(null);

  useEffect(() => {
    const currentRef = sectionRef.current; // Armazena a referência atual em uma variável

    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          currentRef.classList.add('visible');
        }
      },
      { threshold: 0.1 }
    );

    if (currentRef) {
      observer.observe(currentRef);
    }

    return () => {
      if (currentRef) {
        observer.unobserve(currentRef);
      }
    };
  }, []);

  return (
    <div className="first-section" ref={sectionRef}>
      <div className="content">
        <h1>Soluções em <span className="highlight">Dados</span> & <span className="highlight">IA</span></h1>
        <p style={{ color: 'white' }}>
          Revolucione sua gestão com <span className="highlight">Torres de Controle Inteligentes.</span> <br /> 
          Desenvolvemos sistemas sob medida que integram BI e IA avançada, 
          garantindo decisões precisas e controle total do seu negócio.
        </p>
        <div className="cta-container">
          <button className="first-section-cta-button">Começar Agora</button>
        </div>
      </div>
    </div>
  );
}

export default FirstSection;
