import React from 'react';
import './SecondSection.css';
// Import team member images
import caioImage from '../../../assets/team/caio.jpg';
import alexandreImage from '../../../assets/team/alexandre.jpg';
import danielImage from '../../../assets/team/daniel.webp';
import thiagoImage from '../../../assets/team/thiago.webp';

const teamMembers = [
  {
    name: 'Caio Matsui',
    title: 'Business Specialist / CEO',
    description: 'Com uma vasta experiência no setor, ele é responsável por guiar a equipe em direção ao crescimento e à inovação.',
    socialLinks: ['linkedin'],
    image: caioImage,
  },
  {
    name: 'Alexandre Papandrea',
    title: 'AI & Data Specialist / CTO',
    description: 'Líder no desenvolvimento de soluções tecnológicas avançadas, garante a eficácia das soluções desenvolvidas.',
    socialLinks: ['linkedin'],
    image: alexandreImage,
  },
  {
    name: 'Daniel Rocha',
    title: 'Projects Specialist / Head Projetos',
    description: 'Gerente de projetos complexos, transforma as necessidades dos clientes em produtos, garantindo contínua satisfação.',
    socialLinks: ['youtube', 'facebook', 'linkedin'],
    image: danielImage,
  },
  /*{
    name: 'Thiago Silveira',
    title: 'AI & Python Specialist / Head Dev',
    description: 'Expert na área técnica e em desenvolvimento de software, Thiago cria soluções eficientes e inovadoras.',
    socialLinks: ['facebook', 'youtube', 'linkedin'],
    image: thiagoImage,
  },*/
];

const SecondSection = () => {
  return (
    <div className="second-section">
      <h2 className="section-subtitle">Equipe</h2>
      <h1 className="section-title">Nosso <span className="section-highlight">Time</span> de <span className="section-highlight">Experts</span></h1>
      <div className="team-cards">
        {teamMembers.map((member, index) => (
          <div className="team-card" key={index}>
            <img src={member.image} alt={member.name} />
            <h4>{member.name}</h4>
            <p><strong>{member.title}</strong></p>
            <p>{member.description}</p>
            <div className="social-icons">
              {member.socialLinks.map((link, i) => (
                <a href={`https://${link}.com`} key={i} target="_blank" rel="noopener noreferrer">
                  <i className={`fab fa-${link}`}></i>
                </a>
              ))}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default SecondSection;
