import React, { useState } from 'react';
import './DocAI.css';
import { 
  FaFileAlt, 
  FaArrowRight, 
  FaArrowLeft, 
  FaRocket, 
  FaCogs, 
  FaSearch,
  FaCheckCircle,
  FaDatabase,
  FaCloudUploadAlt,
  FaChartLine,
  FaShieldAlt,
  FaBolt
} from 'react-icons/fa';

function DocAI() {
  const [currentSlide, setCurrentSlide] = useState(0);

  const slides = [
    {
      id: 'intro',
      title: 'DocAI Platform',
      subtitle: 'Automatize a extração e processamento de documentos com Inteligência Artificial',
      content: (
        <div className="hero-content">
          <FaFileAlt className="hero-icon" />
          <div className="hero-stats">
            <div className="stat-item">
              <h3>99%</h3>
              <p>Precisão na Extração</p>
            </div>
            <div className="stat-item">
              <h3>90%</h3>
              <p>Redução de Tempo</p>
            </div>
            <div className="stat-item">
              <h3>-60%</h3>
              <p>Custos Operacionais</p>
            </div>
          </div>
        </div>
      )
    },
    {
      id: 'how-it-works',
      title: 'Como Funciona',
      content: (
        <div className="process-steps">
          <div className="step">
            <FaCloudUploadAlt className="step-icon" />
            <h4>1. Upload</h4>
            <p>Envie documentos em qualquer formato</p>
          </div>
          <div className="step">
            <FaSearch className="step-icon" />
            <h4>2. Análise</h4>
            <p>IA identifica e extrai informações</p>
          </div>
          <div className="step">
            <FaDatabase className="step-icon" />
            <h4>3. Processamento</h4>
            <p>Classificação e validação automática</p>
          </div>
          <div className="step">
            <FaCheckCircle className="step-icon" />
            <h4>4. Resultado</h4>
            <p>Dados estruturados e prontos para uso</p>
          </div>
        </div>
      )
    },
    {
      id: 'features',
      title: 'Recursos Avançados',
      content: (
        <div className="features-grid">
          <div className="feature-card">
            <FaBolt className="feature-icon" />
            <h3>Processamento em Lote</h3>
            <p>Milhares de documentos simultaneamente</p>
          </div>
          <div className="feature-card">
            <FaShieldAlt className="feature-icon" />
            <h3>Segurança Avançada</h3>
            <p>Criptografia e conformidade com LGPD</p>
          </div>
          <div className="feature-card">
            <FaChartLine className="feature-icon" />
            <h3>Analytics</h3>
            <p>Insights detalhados do processamento</p>
          </div>
        </div>
      )
    },
    {
      id: 'getting-started',
      title: 'Como Começar',
      content: (
        <div className="getting-started">
          <div className="mindmap">
            <div className="mindmap-center">
              <FaRocket className="mindmap-center-icon" />
              <h3>Comece Aqui</h3>
            </div>
            <div className="mindmap-items">
              <div className="mindmap-item item-1">
                <span className="mindmap-number">1</span>
                <div className="mindmap-content">
                  <h4>Demonstração</h4>
                  <p>Veja o DocAI em ação</p>
                </div>
              </div>
              <div className="mindmap-item item-2">
                <span className="mindmap-number">2</span>
                <div className="mindmap-content">
                  <h4>Configuração</h4>
                  <p>Setup personalizado</p>
                </div>
              </div>
              <div className="mindmap-item item-3">
                <span className="mindmap-number">3</span>
                <div className="mindmap-content">
                  <h4>Integração</h4>
                  <p>Conexão com seus sistemas</p>
                </div>
              </div>
              <div className="mindmap-item item-4">
                <span className="mindmap-number">4</span>
                <div className="mindmap-content">
                  <h4>Go Live</h4>
                  <p>Início das operações</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      )
    },
    {
      id: 'pricing',
      title: 'Planos e Preços',
      subtitle: 'Escolha o plano ideal para seu volume de documentos',
      content: (
        <div className="pricing-cards">
          <div className="price-card">
            <h3>Starter</h3>
            <div className="price">R$ 997<span>/mês</span></div>
            <ul>
              <li>Até 5.000 páginas/mês</li>
              <li>1 tipo de documento</li>
              <li>Suporte 8/5</li>
              <li>API REST</li>
              <li>Dashboard básico</li>
            </ul>
            <button className="price-button">Começar Agora</button>
          </div>
          <div className="price-card business">
            <h3>Business</h3>
            <div className="price">R$ 2.497<span>/mês</span></div>
            <ul>
              <li>Até 25.000 páginas/mês</li>
              <li>3 tipos de documentos</li>
              <li>Suporte 8/5</li>
              <li>API REST + SDK</li>
              <li>Dashboard completo</li>
              <li>Treinamento incluído</li>
            </ul>
            <button className="price-button">Contratar Agora</button>
          </div>
          <div className="price-card enterprise">
            <h3>Enterprise</h3>
            <div className="price">Personalizado</div>
            <ul>
              <li>Volume ilimitado</li>
              <li>Tipos ilimitados de documentos</li>
              <li>Suporte 24/7</li>
              <li>API completa</li>
              <li>Analytics avançado</li>
              <li>Consultoria dedicada</li>
            </ul>
            <button className="price-button">Falar com Consultor</button>
          </div>
        </div>
      )
    }
  ];

  const nextSlide = () => {
    setCurrentSlide((prev) => (prev + 1) % slides.length);
  };

  const prevSlide = () => {
    setCurrentSlide((prev) => (prev - 1 + slides.length) % slides.length);
  };

  return (
    <div className="doc-ai-page">
      <div className="page-header">
        <h1 className="main-title">DocAI</h1>
        <div className="title-underline"></div>
      </div>
      
      <div className="presentation-container">
        <div className="slide-controls">
          <button onClick={prevSlide} className="nav-button">
            <FaArrowLeft />
          </button>
          <div className="slide-indicators">
            {slides.map((_, index) => (
              <div
                key={index}
                className={`indicator ${index === currentSlide ? 'active' : ''}`}
                onClick={() => setCurrentSlide(index)}
              />
            ))}
          </div>
          <button onClick={nextSlide} className="nav-button">
            <FaArrowRight />
          </button>
        </div>

        <div className="slide-content">
          <h1>{slides[currentSlide].title}</h1>
          {slides[currentSlide].subtitle && (
            <p className="slide-subtitle">{slides[currentSlide].subtitle}</p>
          )}
          {slides[currentSlide].content}
        </div>
      </div>
    </div>
  );
}

export default DocAI; 