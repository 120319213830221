// components/Header/Header.js
import React, { useState, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import logo from './logo.png';
import './Header.css';

function Header() {
  const location = useLocation();
  const [isVisible, setIsVisible] = useState(true);
  const [prevScrollPos, setPrevScrollPos] = useState(0);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [activeDropdown, setActiveDropdown] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const currentScrollPos = window.pageYOffset;
      setIsVisible(prevScrollPos > currentScrollPos || currentScrollPos < 10);
      setPrevScrollPos(currentScrollPos);
    };

    window.addEventListener('scroll', handleScroll);

    return () => window.removeEventListener('scroll', handleScroll);
  }, [prevScrollPos]);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const toggleDropdown = (e) => {
    e.preventDefault();
    setActiveDropdown(!activeDropdown);
  };

  return (
    <>
      <header className={`header ${!isVisible ? 'header--hidden' : ''}`}>
        <div className="logo-container">
          <Link to="/" className="logo">
            <img src={logo} alt="Dados Inteligentes Logo" className="logo-img" />
            <span className="logo-text">Dados Inteligentes</span>
          </Link>
        </div>
        <nav className={`nav-container ${isMenuOpen ? 'nav-open' : ''}`}>
          <ul>
            <li><Link to="/" className={location.pathname === '/' ? 'active' : ''}>Home</Link></li>
            <li><Link to="/sobre" className={location.pathname === '/sobre' ? 'active' : ''}>Sobre</Link></li>
            <li><Link to="/solucoes" className={location.pathname === '/solucoes' ? 'active' : ''}>Soluções</Link></li>
            <li className={`dropdown ${activeDropdown ? 'active' : ''}`}>
              <Link 
                to="/plataformas" 
                className={location.pathname === '/plataformas' ? 'active' : ''}
                onClick={toggleDropdown}
              >
                Plataformas
              </Link>
              <ul className={`dropdown-menu ${activeDropdown ? 'show' : ''}`}>
                <li><Link to="/plataformas/torre-controle" onClick={() => setIsMenuOpen(false)}>Torre de Controle</Link></li>
                <li><Link to="/plataformas/ai-agendamentos" onClick={() => setIsMenuOpen(false)}>AI Agendamentos</Link></li>
                <li><Link to="/plataformas/ai-agents" onClick={() => setIsMenuOpen(false)}>AI Agents</Link></li>
                <li><Link to="/plataformas/doc-ai" onClick={() => setIsMenuOpen(false)}>DocAI</Link></li>
              </ul>
            </li>
            <li><Link to="/cases" className={location.pathname === '/cases' ? 'active' : ''}>Cases</Link></li>
            <li><Link to="/contato" className={location.pathname === '/contato' ? 'active' : ''}>Contato</Link></li>
          </ul>
        </nav>
        <div className="hamburger" onClick={toggleMenu}>
          <span></span>
          <span></span>
          <span></span>
        </div>
      </header>
      <div className="header-spacer"></div>
    </>
  );
}

export default Header;
