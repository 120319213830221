import React, { useState } from 'react';
import './AIAgendamentos.css';
import { FaCalendarCheck, FaChartLine, FaClock, FaBell, FaArrowRight, FaArrowLeft, FaRocket, FaCogs, FaUsers, FaCheckCircle } from 'react-icons/fa';

function AIAgendamentos() {
  const [currentSlide, setCurrentSlide] = useState(0);

  const slides = [
    {
      id: 'intro',
      title: 'Minner AI Agendamentos',
      subtitle: 'Sistema inteligente de agendamentos que otimiza recursos e reduz no-shows',
      content: (
        <div className="hero-content">
          <FaCalendarCheck className="hero-icon" />
          <div className="hero-stats">
            <div className="stat-item">
              <h3>-40%</h3>
              <p>Redução de No-shows</p>
            </div>
            <div className="stat-item">
              <h3>+30%</h3>
              <p>Eficiência Operacional</p>
            </div>
            <div className="stat-item">
              <h3>95%</h3>
              <p>Satisfação do Cliente</p>
            </div>
          </div>
        </div>
      )
    },
    {
      id: 'how-it-works',
      title: 'Como Funciona',
      content: (
        <div className="process-steps">
          <div className="step">
            <FaCogs className="step-icon" />
            <h4>1. Configuração</h4>
            <p>Configure suas regras e disponibilidade</p>
          </div>
          <div className="step">
            <FaCalendarCheck className="step-icon" />
            <h4>2. Agendamento</h4>
            <p>Cliente agenda pelo canal preferido</p>
          </div>
          <div className="step">
            <FaBell className="step-icon" />
            <h4>3. Lembretes</h4>
            <p>Sistema envia lembretes inteligentes</p>
          </div>
          <div className="step">
            <FaChartLine className="step-icon" />
            <h4>4. Gestão</h4>
            <p>Monitore e otimize resultados</p>
          </div>
        </div>
      )
    },
    {
      id: 'features',
      title: 'Recursos Avançados',
      content: (
        <div className="features-grid">
          <div className="feature-card">
            <FaClock className="feature-icon" />
            <h3>Otimização Automática</h3>
            <p>IA organiza sua agenda de forma eficiente</p>
          </div>
          <div className="feature-card">
            <FaBell className="feature-icon" />
            <h3>Lembretes Inteligentes</h3>
            <p>Notificações personalizadas multicanal</p>
          </div>
          <div className="feature-card">
            <FaChartLine className="feature-icon" />
            <h3>Analytics Avançado</h3>
            <p>Insights detalhados em tempo real</p>
          </div>
        </div>
      )
    },
    {
      id: 'getting-started',
      title: 'Como Começar',
      content: (
        <div className="getting-started">
          <div className="mindmap">
            <div className="mindmap-center">
              <FaRocket className="mindmap-center-icon" />
              <h3>Comece Aqui</h3>
            </div>
            <div className="mindmap-items">
              <div className="mindmap-item item-1">
                <span className="mindmap-number">1</span>
                <div className="mindmap-content">
                  <h4>Demonstração</h4>
                  <p>Conheça todas as funcionalidades</p>
                </div>
              </div>
              <div className="mindmap-item item-2">
                <span className="mindmap-number">2</span>
                <div className="mindmap-content">
                  <h4>Configuração</h4>
                  <p>Setup inicial personalizado</p>
                </div>
              </div>
              <div className="mindmap-item item-3">
                <span className="mindmap-number">3</span>
                <div className="mindmap-content">
                  <h4>Integração</h4>
                  <p>Conexão com seus sistemas</p>
                </div>
              </div>
              <div className="mindmap-item item-4">
                <span className="mindmap-number">4</span>
                <div className="mindmap-content">
                  <h4>Ativação</h4>
                  <p>Sistema pronto para uso</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      )
    },
    {
      id: 'pricing',
      title: 'Planos e Preços',
      subtitle: 'Escolha o plano ideal para seu negócio',
      content: (
        <div className="pricing-cards">
          <div className="price-card">
            <h3>Professional</h3>
            <div className="price">R$ 997<span>/mês</span></div>
            <ul>
              <li>Até 500 agendamentos/mês</li>
              <li>3 integrações</li>
              <li>Suporte 8/5</li>
              <li>Analytics básico</li>
              <li>Lembretes automáticos</li>
            </ul>
            <button className="price-button">Começar Agora</button>
          </div>
          <div className="price-card business">
            <h3>Enterprise</h3>
            <div className="price">Personalizado</div>
            <ul>
              <li>Agendamentos ilimitados</li>
              <li>Integrações ilimitadas</li>
              <li>Suporte 24/7</li>
              <li>Analytics avançado</li>
              <li>IA personalizada</li>
              <li>Consultoria dedicada</li>
            </ul>
            <button className="price-button">Falar com Consultor</button>
          </div>
        </div>
      )
    }
  ];

  const nextSlide = () => {
    setCurrentSlide((prev) => (prev + 1) % slides.length);
  };

  const prevSlide = () => {
    setCurrentSlide((prev) => (prev - 1 + slides.length) % slides.length);
  };

  return (
    <div className="ai-agendamentos-page">
      <div className="page-header">
        <h1 className="main-title">AI Agendamentos</h1>
        <div className="title-underline"></div>
      </div>
      
      <div className="presentation-container">
        <div className="slide-controls">
          <button onClick={prevSlide} className="nav-button">
            <FaArrowLeft />
          </button>
          <div className="slide-indicators">
            {slides.map((_, index) => (
              <div
                key={index}
                className={`indicator ${index === currentSlide ? 'active' : ''}`}
                onClick={() => setCurrentSlide(index)}
              />
            ))}
          </div>
          <button onClick={nextSlide} className="nav-button">
            <FaArrowRight />
          </button>
        </div>

        <div className="slide-content">
          <h1>{slides[currentSlide].title}</h1>
          {slides[currentSlide].subtitle && (
            <p className="slide-subtitle">{slides[currentSlide].subtitle}</p>
          )}
          {slides[currentSlide].content}
        </div>
      </div>
    </div>
  );
}

export default AIAgendamentos; 