import React, { useState, useEffect, useRef } from 'react';
import './TorreControle.css';
import { FaChartLine, FaArrowRight, FaArrowLeft, FaBell, FaDesktop, FaCogs, FaChartBar, FaRocket, FaCheckCircle } from 'react-icons/fa';

function TorreControle() {
  const [currentSlide, setCurrentSlide] = useState(0);

  const touchStartX = useRef(null);
  const touchEndX = useRef(null);

  const handleTouchStart = (e) => {
    touchStartX.current = e.touches[0].clientX;
  };

  const handleTouchMove = (e) => {
    touchEndX.current = e.touches[0].clientX;
  };

  const handleTouchEnd = () => {
    if (!touchStartX.current || !touchEndX.current) return;

    const diffX = touchStartX.current - touchEndX.current;
    const threshold = 50; // minimum distance for swipe

    if (Math.abs(diffX) > threshold) {
      if (diffX > 0) {
        nextSlide();
      } else {
        prevSlide();
      }
    }

    touchStartX.current = null;
    touchEndX.current = null;
  };

  const slides = [
    {
      id: 'intro',
      title: 'Torre de Controle',
      subtitle: 'Monitoramento em tempo real para gestão eficiente de operações',
      content: (
        <div className="hero-content">
          <FaDesktop className="hero-icon" />
          <div className="hero-stats">
            <div className="stat-item">
              <h3>95%</h3>
              <p>Eficiência Operacional</p>
            </div>
            <div className="stat-item">
              <h3>-30%</h3>
              <p>Redução de Custos</p>
            </div>
            <div className="stat-item">
              <h3>+60%</h3>
              <p>Produtividade</p>
            </div>
          </div>
        </div>
      )
    },
    {
      id: 'how-it-works',
      title: 'Como Funciona',
      content: (
        <div className="process-steps">
          <div className="step">
            <FaChartLine className="step-icon" />
            <h4>1. Monitoramento</h4>
            <p>Acompanhamento em tempo real de métricas</p>
          </div>
          <div className="step">
            <FaBell className="step-icon" />
            <h4>2. Alertas</h4>
            <p>Notificações preditivas e preventivas</p>
          </div>
          <div className="step">
            <FaCogs className="step-icon" />
            <h4>3. Otimização</h4>
            <p>Ajustes automáticos de processos</p>
          </div>
          <div className="step">
            <FaChartBar className="step-icon" />
            <h4>4. Análise</h4>
            <p>Relatórios e insights detalhados</p>
          </div>
        </div>
      )
    },
    {
      id: 'features',
      title: 'Recursos Avançados',
      content: (
        <div className="features-grid">
          <div className="feature-card">
            <FaChartLine className="feature-icon" />
            <h3>Monitoramento Real-time</h3>
            <p>Visualização instantânea de KPIs</p>
          </div>
          <div className="feature-card">
            <FaBell className="feature-icon" />
            <h3>Alertas Inteligentes</h3>
            <p>Previsão e prevenção de problemas</p>
          </div>
          <div className="feature-card">
            <FaCheckCircle className="feature-icon" />
            <h3>Automação Avançada</h3>
            <p>Processos otimizados com IA</p>
          </div>
        </div>
      )
    },
    {
      id: 'getting-started',
      title: 'Como Começar',
      content: (
        <div className="getting-started">
          <div className="mindmap">
            <div className="mindmap-center">
              <FaRocket className="mindmap-center-icon" />
              <h3>Comece Aqui</h3>
            </div>
            <div className="mindmap-items">
              <div className="mindmap-item item-1">
                <span className="mindmap-number">1</span>
                <div className="mindmap-content">
                  <h4>Diagnóstico</h4>
                  <p>Análise da sua operação atual</p>
                </div>
              </div>
              <div className="mindmap-item item-2">
                <span className="mindmap-number">2</span>
                <div className="mindmap-content">
                  <h4>Implementação</h4>
                  <p>Configuração e integração</p>
                </div>
              </div>
              <div className="mindmap-item item-3">
                <span className="mindmap-number">3</span>
                <div className="mindmap-content">
                  <h4>Treinamento</h4>
                  <p>Capacitação da equipe</p>
                </div>
              </div>
              <div className="mindmap-item item-4">
                <span className="mindmap-number">4</span>
                <div className="mindmap-content">
                  <h4>Operação</h4>
                  <p>Monitoramento contínuo</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      )
    },
    {
      id: 'pricing',
      title: 'Planos e Preços',
      subtitle: 'Escolha o plano ideal para sua operação',
      content: (
        <div className="pricing-cards">
          <div className="price-card">
            <h3>Business</h3>
            <div className="price">R$ 4997<span>/mês</span></div>
            <ul>
              <li>Até 50 pontos de monitoramento</li>
              <li>5 dashboards personalizados</li>
              <li>Alertas em tempo real</li>
              <li>Suporte 8/5</li>
              <li>Relatórios semanais</li>
            </ul>
            <button className="price-button">Começar Agora</button>
          </div>
          <div className="price-card enterprise">
            <h3>Enterprise</h3>
            <div className="price">Personalizado</div>
            <ul>
              <li>Pontos ilimitados</li>
              <li>Dashboards ilimitados</li>
              <li>Alertas customizados</li>
              <li>Suporte 24/7</li>
              <li>Relatórios personalizados</li>
            </ul>
            <button className="price-button">Falar com Consultor</button>
          </div>
        </div>
      )
    }
  ];

  const nextSlide = () => {
    setCurrentSlide((prev) => (prev + 1) % slides.length);
  };

  const prevSlide = () => {
    setCurrentSlide((prev) => (prev - 1 + slides.length) % slides.length);
  };

  return (
    <div className="torre-controle-page">
      <div className="page-header">
        <h1 className="main-title">Torre de Controle</h1>
        <div className="title-underline"></div>
      </div>
      
      <div 
        className="presentation-container"
        onTouchStart={handleTouchStart}
        onTouchMove={handleTouchMove}
        onTouchEnd={handleTouchEnd}
      >
        <div className="slide-controls">
          <button onClick={prevSlide} className="nav-button">
            <FaArrowLeft />
          </button>
          <div className="slide-indicators">
            {slides.map((_, index) => (
              <div
                key={index}
                className={`indicator ${index === currentSlide ? 'active' : ''}`}
                onClick={() => setCurrentSlide(index)}
              />
            ))}
          </div>
          <button onClick={nextSlide} className="nav-button">
            <FaArrowRight />
          </button>
        </div>

        <div className="slide-content">
          <h1>{slides[currentSlide].title}</h1>
          {slides[currentSlide].subtitle && (
            <p className="slide-subtitle">{slides[currentSlide].subtitle}</p>
          )}
          {slides[currentSlide].content}
        </div>
      </div>
    </div>
  );
}

export default TorreControle; 