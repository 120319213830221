import React, { useState } from 'react';
import { FaTimes, FaFileDownload, FaCheckCircle, FaEnvelope } from 'react-icons/fa';

function DocumentRequestForm({ isOpen, onClose }) {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    company: '',
    phone: ''
  });
  const [isSubmitted, setIsSubmitted] = useState(false);

  const handleSubmit = (e) => {
    e.preventDefault();
    // Add form submission logic here
    console.log('Form submitted:', formData);
    setIsSubmitted(true);
  };

  const handleClose = () => {
    onClose();
    // Reset form and submission state after modal is closed
    setTimeout(() => {
      setIsSubmitted(false);
      setFormData({
        name: '',
        email: '',
        company: '',
        phone: ''
      });
    }, 300); // Small delay to ensure the modal is closed before resetting
  };

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value
    });
  };

  if (!isOpen) return null;

  return (
    <div className="modal-overlay">
      <div className="modal-content">
        <button className="modal-close" onClick={handleClose}>
          <FaTimes />
        </button>
        
        {!isSubmitted ? (
          <>
            <div className="modal-header">
              <FaFileDownload className="modal-icon" />
              <h2>Receba nossa documentação completa</h2>
              <p>Preencha o formulário abaixo para receber um documento detalhado sobre nossa solução</p>
            </div>

            <form onSubmit={handleSubmit}>
              <div className="form-group">
                <input
                  type="text"
                  name="name"
                  placeholder="Nome completo"
                  value={formData.name}
                  onChange={handleChange}
                  required
                />
              </div>
              <div className="form-group">
                <input
                  type="email"
                  name="email"
                  placeholder="Email corporativo"
                  value={formData.email}
                  onChange={handleChange}
                  required
                />
              </div>
              <div className="form-group">
                <input
                  type="text"
                  name="company"
                  placeholder="Empresa"
                  value={formData.company}
                  onChange={handleChange}
                  required
                />
              </div>
              <div className="form-group">
                <input
                  type="tel"
                  name="phone"
                  placeholder="Telefone"
                  value={formData.phone}
                  onChange={handleChange}
                  required
                />
              </div>
              <button type="submit" className="submit-button">
                Receber Documentação
              </button>
            </form>
          </>
        ) : (
          <div className="success-message">
            <div className="success-icon-wrapper">
              <FaCheckCircle className="success-icon" />
            </div>
            <h2>Solicitação enviada!</h2>
            <div className="success-details">
              <FaEnvelope className="email-icon" />
              <p>
                Enviamos um email para <strong>{formData.email}</strong> com a documentação completa da nossa solução.
              </p>
            </div>
            <div className="success-info">
              <p>Não recebeu? Verifique sua caixa de spam ou entre em contato com nosso suporte.</p>
            </div>
            <button onClick={handleClose} className="close-success-button">
              Fechar
            </button>
          </div>
        )}
      </div>
    </div>
  );
}

export default DocumentRequestForm;