import React from 'react';
import { FaCalendarAlt, FaChartLine, FaLightbulb } from 'react-icons/fa';
import './Cases.css';

function Cases() {
  return (
    <div className="cases-page">
      <section className="hero-section">
        <h1><span className="highlight">Cases</span> de Sucesso</h1>
        <p>Transformando negócios com soluções de IA desde 2022 | Inovação. Resultados. Excelência.</p>
      </section>

      <section className="company-intro">
        <h2>Pioneiros em IA no Sul de Minas</h2>
        <p>A Dados Inteligentes nasceu em 2022 como a primeira empresa a implementar tecnologias de Inteligência Artificial no Sul de Minas. Hoje, atendemos diversos clientes nos estados de Minas Gerais e São Paulo, abrangendo múltiplos setores.</p>
      </section>

      <section className="products-section">
        <h2>Nossos Produtos</h2>
        <div className="product-cards">
          <div className="product-card">
            <FaCalendarAlt className="product-icon" />
            <h3>Minner AI Agendamentos</h3>
            <p>Sistema inteligente de agendamentos que otimiza o tempo e recursos da sua empresa.</p>
          </div>
          <div className="product-card">
            <FaChartLine className="product-icon" />
            <h3>Torre de Controle</h3>
            <p>Plataforma de monitoramento em tempo real para gestão eficiente de operações.</p>
          </div>
        </div>
      </section>

      <section className="success-stories">
        <h2>Histórias de Sucesso</h2>
        <div className="story-card">
          <FaLightbulb className="story-icon" />
          <h3>Indústria Manufatureira</h3>
          <p>Implementamos um sistema de previsão de demanda que reduziu o estoque em 30% e aumentou a eficiência operacional em 25%.</p>
        </div>
        <div className="story-card">
          <FaLightbulb className="story-icon" />
          <h3>Varejo</h3>
          <p>Nossa solução de IA para atendimento ao cliente aumentou a satisfação em 40% e reduziu o tempo de resposta em 60%.</p>
        </div>
      </section>

      <section className="cta-section">
        <h2>Pronto para transformar seu negócio?</h2>
        <p>Entre em contato conosco e descubra como a Dados Inteligentes pode impulsionar sua empresa com soluções de IA de ponta.</p>
        <button className="cta-button">Solicite uma Demonstração</button>
      </section>
    </div>
  );
}

export default Cases;