import React, { useState, useEffect, useRef } from 'react';
import { FaMoneyBillWave, FaRocket, FaUserTie, FaChartLine } from 'react-icons/fa';
import './FourthSection.css';

const benefits = [
  {
    icon: <FaMoneyBillWave />,
    title: 'Preço Competitivo',
    description: 'Oferecemos soluções de alta qualidade a preços acessíveis, garantindo o melhor custo-benefício para o seu investimento em tecnologia.',
    achievement: 'Economia média de 30%'
  },
  {
    icon: <FaRocket />,
    title: 'Entrega Rápida',
    description: 'Nossa metodologia ágil e equipe experiente garantem entregas rápidas e eficientes, mantendo você à frente no mercado.',
    achievement: '95% Entregas Antes do Prazo'
  },
  {
    icon: <FaUserTie />,
    title: 'Experiência Comprovada',
    description: 'Com anos de experiência no mercado, nossa equipe possui o conhecimento necessário para enfrentar os desafios mais complexos.',
    achievement: '+10 Clientes Satisfeitos'
  },
  {
    icon: <FaChartLine />,
    title: 'Melhoria da Excelência Operacional',
    description: 'Nossas soluções são projetadas para otimizar seus processos, aumentando a eficiência e produtividade da sua empresa.',
    achievement: '+40% Produtividade'
  }
];

function FourthSection() {
    const [activeIndex, setActiveIndex] = useState(0);
    const carouselRef = useRef(null);
  
    useEffect(() => {
      const handleResize = () => {
        if (window.innerWidth <= 768) {
          carouselRef.current.scrollTo({
            left: activeIndex * carouselRef.current.offsetWidth * 0.8,
            behavior: 'smooth'
          });
        }
      };
  
      window.addEventListener('resize', handleResize);
      return () => window.removeEventListener('resize', handleResize);
    }, [activeIndex]);
  
    const handleDotClick = (index) => {
      setActiveIndex(index);
      if (window.innerWidth <= 768) {
        carouselRef.current.scrollTo({
          left: index * carouselRef.current.offsetWidth * 0.8,
          behavior: 'smooth'
        });
      }
    };
  
    return (
      <section className="fourth-section">
        <div className="fourth-section-content">
          <h2 className="fourth-section-title">Por que escolher a <span className="highlight">Dados Inteligentes</span>?</h2>
          <p className="fourth-section-subtitle">Descubra como podemos impulsionar o <span className="highlight">sucesso</span> do seu negócio</p>
          <div className="benefits-grid" ref={carouselRef}>
            {benefits.map((benefit, index) => (
              <div key={index} className="benefit-item">
                <div className="benefit-icon">{benefit.icon}</div>
                <h3 className="benefit-title">{benefit.title}</h3>
                <p className="benefit-description">{benefit.description}</p>
                <div className="achievement-badge">{benefit.achievement}</div>
              </div>
            ))}
          </div>
          {window.innerWidth <= 768 && (
            <div className="carousel-dots">
              {benefits.map((_, index) => (
                <span
                  key={index}
                  className={`dot ${index === activeIndex ? 'active' : ''}`}
                  onClick={() => handleDotClick(index)}
                />
              ))}
            </div>
          )}
        </div>
      </section>
    );
  }
  
  export default FourthSection;