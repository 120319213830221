import React, { useState, useEffect, useRef } from 'react';
import './Sobre.css';
import { FaLightbulb, FaUsers, FaRocket, FaMapMarkedAlt, FaDatabase, FaCogs, FaRobot, FaChartLine, FaChartBar, FaLaptopCode, FaAward, FaHandshake, FaChartPie, FaCode, FaBuilding } from 'react-icons/fa';
import norbrasilLogo from '../../assets/logos/norbrasil.png';
import prolifeLogo from '../../assets/logos/prolife.png';
import atualLogo from '../../assets/logos/atual.png';
import icebomLogo from '../../assets/logos/icebom.png';
import lgmLogo from '../../assets/logos/lgm.png';

function Sobre() {
  const [activeIndices, setActiveIndices] = useState({
    diferenciais: 0,
    clientes: 0
  });
  const carouselRefs = useRef([]);

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth <= 768) {
        Object.keys(activeIndices).forEach((key, index) => {
          if (carouselRefs.current[index]) {
            carouselRefs.current[index].scrollTo({
              left: activeIndices[key] * carouselRefs.current[index].offsetWidth,
              behavior: 'smooth'
            });
          }
        });
      }
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, [activeIndices]);

  const handleDotClick = (section, index) => {
    setActiveIndices(prev => ({ ...prev, [section]: index }));
    if (window.innerWidth <= 768) {
      const sectionIndex = ['diferenciais', 'clientes'].indexOf(section);
      if (carouselRefs.current[sectionIndex]) {
        carouselRefs.current[sectionIndex].scrollTo({
          left: index * carouselRefs.current[sectionIndex].offsetWidth,
          behavior: 'smooth'
        });
      }
    }
  };

  const diferenciais = [
    { icon: <FaLightbulb />, title: 'Inovação Constante', desc: 'Na vanguarda da IA:', bullets: ['Investimento contínuo em P&D', 'Parcerias com universidades', 'Participação em conferências globais', 'Laboratório de inovação interno'] },
    { icon: <FaUsers />, title: 'Foco no Cliente', desc: 'Soluções adaptadas às suas necessidades:', bullets: ['Acompanhamento personalizado', 'Suporte 24/7', 'Treinamento e capacitação', 'Feedback contínuo e iterações'] },
    { icon: <FaRocket />, title: 'Resultados Comprovados', desc: 'Impacto mensurável nos negócios:', bullets: ['ROI significativo', 'Cases de sucesso em diversos setores', 'Métricas de desempenho transparentes', 'Garantia de satisfação'] },
    { icon: <FaMapMarkedAlt />, title: 'Presença Regional', desc: 'Atendimento em MG e SP:', bullets: ['Conhecimento do mercado local', 'Capacidade de escalar nacionalmente', 'Rede de parceiros estratégicos', 'Suporte local e remoto'] },
    { icon: <FaAward />, title: 'Excelência Técnica', desc: 'Equipe altamente qualificada:', bullets: ['Especialistas em IA e ciência de dados', 'Desenvolvedores full-stack', 'Arquitetos de soluções', 'Consultores de negócios'] },
    { icon: <FaHandshake />, title: 'Parceria Estratégica', desc: 'Alinhamento com seus objetivos:', bullets: ['Consultoria estratégica', 'Roadmap de transformação digital', 'Acompanhamento de longo prazo', 'Co-criação de soluções'] }
  ];

  const renderCarousel = (items, section) => (
    <>
      <div className={`${section}-grid`} ref={el => carouselRefs.current[['diferenciais', 'clientes'].indexOf(section)] = el}>
        {items.map((item, index) => {
          if (section === 'clientes') {
            return (
              <div key={index} className="cliente-card">
                <img src={item.logo} alt={`Logo ${item.nome}`} className="cliente-logo" />
                <h3 className="cliente-nome">{item.nome}</h3>
                <p className="cliente-setor">{item.setor}</p>
                <p className="cliente-resultado">{item.resultado}</p>
              </div>
            );
          }
          return (
            <div key={index} className={`${section === 'diferenciais' ? 'diferencial-item' : ''}`}>
              {item.icon}
              <h3>{item.title}</h3>
              <p>{item.desc}</p>
              <ul>
                {item.bullets && item.bullets.map((bullet, idx) => (
                  <li key={idx}>{bullet}</li>
                ))}
              </ul>
            </div>
          );
        })}
      </div>
      {window.innerWidth <= 768 && (
        <div className="carousel-dots">
          {items.map((_, index) => (
            <span
              key={index}
              className={`dot ${index === activeIndices[section] ? 'active' : ''}`}
              onClick={() => handleDotClick(section, index)}
            />
          ))}
        </div>
      )}
    </>
  );

  return (
    <div className="sobre-page">
      <section className="hero-section">
        <h1>Transformando o Futuro com <span className="highlight">Dados Inteligentes</span></h1>
        <p>Pioneiros em IA no Sul de Minas desde 2022 | Inovação. Resultados. Excelência.</p>
      </section>

      <section className="nossa-historia">
        <h2>Nossa Trajetória de Inovação</h2>
        <div className="journey-grid">
          <div className="journey-item">
            <div className="journey-content">
              <h3>2022</h3>
              <p>Nascimento da Dados Inteligentes com uma visão clara: democratizar a IA para empresas regionais. Primeiros projetos de automação e análise preditiva.</p>
            </div>
          </div>
          <div className="journey-item">
            <div className="journey-content">
              <h3>2023</h3>
              <p>Expansão estratégica para São Paulo. Lançamento do Minner AI Agendamentos, nossa solução proprietária que revolucionou a gestão de agendamentos com IA.</p>
            </div>
          </div>
          <div className="journey-item">
            <div className="journey-content">
              <h3>2024</h3>
              <p>Consolidação como referência em IA no Sudeste. Desenvolvimento de soluções customizadas para diversos setores e reconhecimento por importantes players do mercado.</p>
            </div>
          </div>
          <div className="journey-item">
            <div className="journey-content">
              <h3>Futuro</h3>
              <p>Comprometidos com a inovação contínua, expandindo nossa presença nacional e desenvolvendo novas soluções de IA que transformam negócios e impactam vidas.</p>
            </div>
          </div>
        </div>
      </section>

      <section className="nossos-clientes">
        <h2>Clientes</h2>
        <p>&nbsp;</p>
        {renderCarousel([
          {
            logo: norbrasilLogo,
            nome: 'Norbrasil',
            setor: 'Serviços',
            resultado: 'Inserir Texto'
          },
          {
            logo: atualLogo,
            nome: 'Atual',
            setor: 'Serviços',
            resultado: 'Inserir Texto'
          },
          {
            logo: prolifeLogo,
            nome: 'Prolife',
            setor: 'Saúde',
            resultado: 'Inserir Texto'
          },
          {
            logo: icebomLogo,
            nome: 'Icebom',
            setor: 'Alimentos',
            resultado: 'Redução no tempo para expedição em 30%'
          },
          {
            logo: lgmLogo,
            nome: 'LGM Automação',
            setor: 'Serviços',
            resultado: 'Inserir Texto'
          }
        ], 'clientes')}
      </section>

      <section className="nossos-diferenciais">
        <h2>Por Que Escolher a Dados Inteligentes</h2>
        <p>&nbsp;</p>
        {renderCarousel(diferenciais, 'diferenciais')}
      </section>

      <section className="cta-section">
        <h2>Pronto para Liderar a Revolução Digital?</h2>
        <p>Agende uma consulta gratuita e descubra como podemos transformar seu negócio com IA personalizada.</p>
        <button className="cta-button">Inicie Sua Jornada de IA</button>
      </section>
    </div>
  );
}

export default Sobre;
