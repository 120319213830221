// App.js
import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Header from './components/Header/Header';
import Footer from './components/Footer/Footer';
import Home from './pages/Home/Home';
import Sobre from './pages/Sobre/Sobre';
import Solucoes from './pages/Solucoes/Solucoes';
import SolutionsPage from './pages/Solucoes_old/Solucoes_old';
import Contato from './pages/Contato/Contato';
import Cases from './pages/Cases/Cases';
import Plataformas from './pages/Plataformas/Plataformas';
import ScrollToTop from './components/ScrollToTop';
import AIAgents from './pages/Plataformas/AIAgents/AIAgents';
import TorreControle from './pages/Plataformas/TorreControle/TorreControle';
import AIAgendamentos from './pages/Plataformas/AIAgendamentos/AIAgendamentos';
import DocAI from './pages/Plataformas/DocAI/DocAI';

function App() {
  return (
    <Router>
      <div className="App">
        <Header />
        <main className="main-content">
          <ScrollToTop />
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/sobre" element={<Sobre />} />
            <Route path="/solucoes" element={<Solucoes />} />
            <Route path="/solucoes1" element={<SolutionsPage />} />
            <Route path="/contato" element={<Contato />} />
            <Route path="/cases" element={<Cases />} />
            <Route path="/plataformas" element={<Plataformas />} />
            <Route path="/plataformas/ai-agents" element={<AIAgents />} />
            <Route path="/plataformas/torre-controle" element={<TorreControle />} />
            <Route path="/plataformas/ai-agendamentos" element={<AIAgendamentos />} />
            <Route path="/plataformas/doc-ai" element={<DocAI />} />
          </Routes>
        </main>
        <Footer />
      </div>
    </Router>
  );
}

export default App;