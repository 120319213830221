import React, { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { FaChessRook, FaRobot, FaCogs, FaLaptopCode } from 'react-icons/fa';
import './ThirdSection.css';

const services = [
  {
    icon: <FaChessRook />,
    title: 'Torre de Controle',
    description: 'Plataforma integrada de visualização e análise de dados:',
    bullets: [
      'Integração com dados dos clientes',
      'Aplicativo pronto para visualizar dados',
      'Análise de tendências',
      'Dashboards personalizadas'
    ],
    color: '#000000',
    route: '/cases/torre-de-controle'
  },
  {
    icon: <FaRobot />,
    title: 'Inteligência Artificial',
    description: 'Soluções de IA personalizadas:',
    bullets: [
      'Assistentes Inteligentes Integrados com CRM e ERP',
      'Chatbots de Atendimento no WhatsApp',
      'AI Agents',
      'Machine Learning'
    ],
    color: '#FFC107',
    route: '/cases/inteligência-artificial'
  },
  {
    icon: <FaCogs />,
    title: 'Automação de Processos',
    description: 'Aumentamos a eficiência operacional com:',
    bullets: [
      'RPA (Robotic Process Automation)',
      'Automação de Fluxos de Trabalho',
      'Business Process Optimization',
      'Integração de Sistemas'
    ],
    color: '#4CAF50',
    route: '/cases/automação-de-processos'
  },
  {
    icon: <FaLaptopCode />,
    title: 'Desenvolvimento de Aplicações',
    description: 'Soluções sob medida para seu negócio:',
    bullets: [
      'Sistemas de gestão ERP/CRM personalizados',
      'Aplicações web responsivas',
      'Aplicativos móveis nativos',
      'Soluções em nuvem escaláveis'
    ],
    color: '#FF5722',
    route: '/cases/desenvolvimento-de-aplicações'
  }
];

function ThirdSection() {
  const navigate = useNavigate();
  const [activeIndex, setActiveIndex] = useState(0);
  const [isMobile, setIsMobile] = useState(false);
  const carouselRef = useRef(null);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    handleResize();
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  useEffect(() => {
    if (isMobile && carouselRef.current) {
      carouselRef.current.scrollTo({
        left: activeIndex * (carouselRef.current.offsetWidth * 0.8),
        behavior: 'smooth'
      });
    }
  }, [activeIndex, isMobile]);

  useEffect(() => {
    const handleScroll = () => {
      if (isMobile && carouselRef.current) {
        const scrollPosition = carouselRef.current.scrollLeft;
        const cardWidth = carouselRef.current.offsetWidth * 0.8;
        const newIndex = Math.round(scrollPosition / cardWidth);
        setActiveIndex(newIndex);
      }
    };

    const carousel = carouselRef.current;
    if (carousel) {
      carousel.addEventListener('scroll', handleScroll);
      return () => carousel.removeEventListener('scroll', handleScroll);
    }
  }, [isMobile]);

  const handleCardClick = (route) => {
    navigate(route);
  };

  const handleDotClick = (index) => {
    setActiveIndex(index);
  };

  return (
    <div className="third-section">
      <h2 className="section-subtitle">Serviços Personalizados</h2>
      <h1 className="section-title">Serviços <span className="highlight">Sob Medida</span> para <span className="highlight">Transformar</span></h1>
      <h3 className="section-description">Comece a <span className="highlight">Transformação</span> tecnológica em seu negócio</h3>
      <div className="services-grid" ref={carouselRef}>
        {services.map((service, index) => (
          <div 
            key={index} 
            className="service-card" 
            onClick={() => handleCardClick(service.route)}
            style={{animationDelay: `${index * 0.1}s`}}
          >
            <div className="service-header">
              <div className="service-icon" style={{backgroundColor: service.color}}>{service.icon}</div>
              <h3 className="service-title">{service.title}</h3>
            </div>
            <p className="service-description">{service.description}</p>
            <ul className="service-bullets">
              {service.bullets.map((bullet, bulletIndex) => (
                <li key={bulletIndex}>{bullet}</li>
              ))}
            </ul>
            <div className="service-tooltip">Clique para saber mais</div>
          </div>
        ))}
      </div>
      {isMobile && (
        <div className="carousel-dots">
          {services.map((_, index) => (
            <span
              key={index}
              className={`dot ${index === activeIndex ? 'active' : ''}`}
              onClick={() => handleDotClick(index)}
            />
          ))}
        </div>
      )}
    </div>
  );
}

export default ThirdSection;
